<template>
  <header
    class="PageHeader header sticky top-0 w-full z-header"
    :class="{
      'top-40': userStore.userProfile.impersonatingCompany
    }"
  >
    <!-- * * * * * * * mobile -->
    <div
      class="[@media(min-width:1025px)]:hidden h-[52px]
    bg-lightest shadow-bottomLine
    flex justify-between items-center
"
    >
      <div class="flex-1 flex flex-row justify-start items-center gap-12">
        <div class="pl-12">
          <button
            class="w-24 h-24 flex items-center"
            :title="$t('header.menu.open')"
            @click="openMenu"
          >
            <img
              src="/icons/menu.svg"
              class="w-24 h-24"
              :alt="$t('header.menu')"
            >
          </button>
        </div>
        <nuxt-link :to="manualUrl('/')" class="block basis-[177px] shrink-0">
          <img
            :src="`/logos/session-logo_dark.svg`"
            class="w-full h-auto"
            alt="logo"
          >
        </nuxt-link>
      </div>
      <div class="flex-1 pr-12 gap-16 flex justify-end items-center">
        <button
          v-if="!userStore.isLoggedIn"
          class="flex items-center"
          @click="myPage()"
        >
          <img
            :src="isSeller ? '/icons/laptop-performance.svg' : '/icons/mypage.svg'"
            class="w-24 h-24"
            :alt="isSeller ? $t('header.sellerTools') : $t('header.myPage')"
          >
        </button>

        <button
          v-if="userStore.isLoggedIn && !userProfile.impersonatingCompany"
          class="bg-purpleLight w-[30px] h-[30px] border-2 border-lightest flex items-center justify-center rounded-full"
          :class="{
            'bg-warningLight': isCustomerSuccess,
          }"
          @click="myPage()"
        >
          <img
            :src="isCustomerSuccess ? '/icons/headset.svg' : '/icons/smiley-purple.svg'"
            class="w-[20px] h-[20px]"
            :alt="$t('header.myPage')"
          >
        </button>

        <nuxt-link
          v-if="(isSeller || isCustomerSuccess) && userProfile.impersonatingCompany"
          class="flex items-center"
          :to="isSeller ? salesUrl('customers') : accountUrl('dashboard')"
        >
          <div class="flex">
            <div
              class="w-[30px] h-[30px] border-2 border-lightest flex items-center justify-center rounded-full"
              :class="{'bg-brandPrimary': isSeller, 'bg-warningLight': isCustomerSuccess}"
            >
              <img
                v-if="isSeller"
                src="/icons/smiley-cool.svg"
                class="w-[20px] h-[20px]"
              >
              <img
                v-else
                src="/icons/headset.svg"
                class="w-[20px] h-[20px]"
                :alt="$t('header.myPage')"
              >
            </div>
          </div>
        </nuxt-link>
        <button
          v-if="(isSeller || isCustomerSuccess) && userProfile.impersonatingCompany"
          class="flex items-center"
          @click="isCustomerSuccess ? uiStore.setShowLogInAsCustomer(true) : uiStore.setImpersonateModal(true)"
        >
          <img
            src="/icons/change-customer.svg"
            class="w-24 h-24"
            :alt="$t('header.impersonate')"
          >
        </button>
        <button
          v-else-if="(isSeller || isCustomerSuccess) && !userProfile.impersonatingCompany"
          class="flex items-center"
          @click="onImpersonate"
        >
          <img
            src="/icons/change-customer.svg"
            class="w-24 h-24"
            :alt="$t('header.impersonate')"
          >
        </button>
        <nuxt-link
          v-if="userStore.isLoggedIn && (!(isSeller || isCustomerSuccess) || (isSeller || isCustomerSuccess) && userProfile.impersonatingCompany )"
          :to="accountUrl('favorites')"
          :class="{
            'hidden': isSeller || isCustomerSuccess,
          }"
        >
          <img
            src="/icons/heart.svg"
            class="w-24 h-24"
            :alt="$t('header.favorites')"
          >
        </nuxt-link>
        <CartIcon />
      </div>
    </div>

    <!-- * * * * * * * desktop -->
    <div
      class="hidden [@media(min-width:1025px)]:block
             h-[53px] w-full
             bg-lightest shadow-bottomLine
            relative z-modalsContent type-base leading-single"
    >
      <div class="flex layout-container items-center justify-between h-[53px]">
        <div class="basis-1/2 flex flex-row items-center gap-8">
          <label for="desktopSearchInput">
            <img
              src="/icons/search.svg"
              class="w-24 h-24"
              :alt="$t('search.placeholder')"
            >
          </label>
          <input
            id="desktopSearchInput"
            ref="desktopSearchInput"
            v-model="search"
            type="text"
            :placeholder="$t('search.placeholder')"
            class="w-full placeholder-opacity-100 pt-1"
            @focus="showDesktopSearch"
            @keyup.esc="closeDesktopSearch"
            @keyup.enter="goToSearchPage"
          >
        </div>
        <nuxt-link
          v-if="!uiStore.showDesktopSearch"
          :to="manualUrl('/')"
          class="block basis-[166px] shrink-0"
        >
          <img
            :src="`/logos/session-logo_dark.svg`"
            class="w-full h-auto"
            alt="logo"
          >
        </nuxt-link>
        <div
          v-if="!uiStore.showDesktopSearch"
          class="basis-1/2 flex justify-end items-center gap-16"
        >
          <button
            v-if="!userStore.isLoggedIn && !isSeller && !isCustomerSuccess"
            class="flex items-center"
            @click="myPage()"
          >
            <img
              :src="isSeller ? '/icons/laptop-performance.svg' : '/icons/mypage.svg'"
              class="w-24 h-24"
              :alt="isSeller ? $t('header.sellerTools') : $t('header.myPage')"
            >
          </button>

          <button
            v-if="userStore.isLoggedIn && !userProfile.impersonatingCompany"
            class="bg-purpleLight w-[30px] h-[30px] border-2 border-lightest flex items-center justify-center rounded-full"
            :class="{
              'bg-warningLight': isCustomerSuccess,
            }"
            @click="myPage()"
          >
            <img
              :src="isCustomerSuccess ? '/icons/headset.svg' : '/icons/smiley-purple.svg'"
              class="w-[20px] h-[20px]"
              :alt="$t('header.myPage')"
            >
          </button>

          <button
            v-if="userStore.isLoggedIn && userProfile.impersonatingCompany"
            class="w-[30px] h-[30px] border-2 border-lightest flex items-center justify-center rounded-full"
            :class="{'bg-brandPrimary' : isSeller, 'bg-warningLight': isCustomerSuccess}"
            @click="myPage()"
          >
            <img
              :src=" isSeller ? '/icons/smiley-cool.svg' : '/icons/headset.svg'"
              class="w-[20px] h-[20px]"
              :alt="$t('header.myPage')"
            >
          </button>

          <button
            v-if="(isSeller || isCustomerSuccess) && !userProfile.impersonatingCompany"
            class="flex items-center"
            @click="onImpersonate"
          >
            <img
              src="/icons/change-customer.svg"
              class="w-24 h-24"
              :alt="$t('header.impersonate')"
            >
          </button>

          <button
            v-if="(isSeller || isCustomerSuccess) && userProfile.impersonatingCompany"
            class="flex items-center"
            @click="isCustomerSuccess ? uiStore.setShowLogInAsCustomer(true) : uiStore.setImpersonateModal(true)"
          >
            <img
              src="/icons/change-customer.svg"
              class="w-24 h-24"
              :alt="$t('header.impersonate')"
            >
          </button>

          <nuxt-link
            v-if="userStore.isLoggedIn && (!(isSeller || isCustomerSuccess) || (isSeller || isCustomerSuccess) && userProfile.impersonatingCompany )"
            class="flex items-center"
            :class="{
              'hidden': isCustomerSuccess
            }"
            :to="isSeller ? salesUrl('favorites') : accountUrl('favorites')"
          >
            <img
              src="/icons/heart.svg"
              class="w-24 h-24"
              :alt="$t('header.favorites')"
            >
          </nuxt-link>

          <CartIcon />
        </div>
        <button
          v-if="uiStore.showDesktopSearch"
          class="flex items-center"
          @click="closeDesktopSearch"
        >
          <div class="mr-12">{{ $t('search.cancel') }}</div>
          <img
            src="/icons/close.svg"
            class="w-24 h-24"
            :alt="$t('search.cancel')"
          >
        </button>
      </div>
      <transition name="fade">
        <div v-show="uiStore.showDesktopSearch" class="relative">
          <div class="absolute top-0 left-0 w-full bg-lightest border-t border-light searchResultHeight">
            <SearchResult
              v-if="uiStore.searchOpenedOnceDesk"
              :search="search"
              @select="(e) => search = e"
              @go-to-search-page="goToSearchPage"
            />
          </div>
        </div>
      </transition>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';
import CartIcon from '~/components/cart/CartIcon.vue';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import { useUserStore } from '~/store/user';
import { storeToRefs } from 'pinia';
import SearchResult from '~/components/search/SearchResult.vue';

const { accountUrl, manualUrl, salesUrl } = useLinkReplacer();

const { $t } = useNuxtApp();
const uiStore = useUiStore();
const userStore = useUserStore();

const { userProfile } = storeToRefs(userStore);

const openMenu = () => {
  uiStore.sideMenuParents = [];
  uiStore.setShowSideMenu(true);
};

const myPage = () => {
  if (isCustomerSuccess.value && !userStore.userProfile.impersonatingCompany) {
    navigateTo(accountUrl('selectCustomer'));
  } else {
    if (userStore.loggedIn) {
      navigateTo(isSeller.value ? salesUrl('customers') : accountUrl('dashboard'));
    } else {
      uiStore.setShowSigninModal(true);
    }

  }
};

const onImpersonate = () => {
  if (isCustomerSuccess.value) {
    uiStore.setShowLogInAsCustomer(true);
  } else {
    uiStore.setImpersonateModal(true);
  }

};

const search = ref('');

const desktopSearchInput = ref<HTMLElement>();
const closeDesktopSearch = () => {
  search.value = '';
  uiStore.showDesktopSearch = false;
  desktopSearchInput.value?.blur();
};

const goToSearchPage = () => {
  uiStore.showDesktopSearch = false;
  desktopSearchInput.value?.blur();
  navigateTo(manualUrl(`/search?q=${search.value}`));
  search.value = '';
};

const isSeller = computed(() => {
  return userStore.isSalesRepUser;
});

const isCustomerSuccess = computed(() => {
  return userStore.isCustomerSuccessUser;
});

const myPagesText = computed(() => {
  if (isSeller.value) {
    return $t('header.sellerTools');
  } else if (isCustomerSuccess.value) {
    return userStore.userProfile.impersonatingCompany ? userStore.userProfile.company.name : $t('header.customerSuccessImpersonate');
  } else if (userStore.isLoggedIn) {
    return userStore.userProfile.company.name;
  } else {
    return $t('header.myPage');
  }
});

const showDesktopSearch = () => {
  uiStore.searchOpenedOnceDesk = true;
  uiStore.showDesktopSearch = true;
};

</script>

<style scoped lang="postcss">
.searchResultHeight {
  max-height: calc(100vh - 53px); /* entire screen - header */
  height: calc(100vh - 53px);
  @apply overflow-auto pb-64;
  /* padding bottom, at least 32 for if sitemessages are hidden */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
