<template>
  <header
    class="CheckoutHeader header bg-lightest text-darkest sticky top-0 w-full z-header"
    :class="{
      'top-40': userStore.userProfile.impersonatingCompany
    }"
  >
    <div
      v-if="userStore.userProfile?.company.suspended && !userStore.userProfile.impersonatingCompany"
      class="relative flex items-center justify-center h-32 w-full type-xs text-lightest bg-darkest"
    >
      {{ $t('company.suspended') }}
    </div>

    <div class="shadow-bottomLine">
      <div class="h-[56px] layout-container flex flex-row items-center justify-start desk:justify-between gap-12">
        <nuxt-link
          :to="manualUrl('/')"
          class="flex flex-row gap-12 items-center desk:basis-[166px]"
          :external="true"
        >
          <img
            src="/icons/arrow-left.svg"
            alt=""
            class="h-24 w-24"
          >
          <span class="hidden desk:inline type-sm">
            {{ $t('checkout.backToShopping') }}
          </span>
        </nuxt-link>
        <nuxt-link
          class="block basis-[177px] desk:basis-[166px] shrink-0"
          :to="manualUrl('/')"
          :external="true"
        >
          <img
            :src="`/logos/session-logo_dark.svg`"
            class="w-full h-auto"
            alt="logo"
          >
        </nuxt-link>
        <button
          class="flex justify-end items-center basis-[166px]"
          @click="uiStore.setShowCheckoutHelp(true)"
        >
          <img
            src="/icons/question-circle.svg"
            alt=""
            class="h-24 w-24"
          >
        </button>
      </div>
    </div>

    <PointshopHead v-if="isPointShop" class="mt-1" />
  </header>
</template>

<script setup lang="ts">
/**
 * Please note, the link to the frontpage has :external="true" to force a full load
 * This is because, if checkout is reloaded, normal navigation will fail
 */
import PointshopHead from '~/components/checkout/PointshopHead.vue';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import { useUiStore } from '~/store/ui';
import { useUserStore } from '~/store/user';

const { manualUrl } = useLinkReplacer();
const uiStore = useUiStore();
const userStore = useUserStore();
const route = useRoute();

const isPointShop = computed(()=> {
  return route.name?.toString()?.endsWith('checkout-pointshop-basketId');
});
</script>

<style scoped lang="postcss">
.header {
  backdrop-filter: blur(15px);
}
</style>
